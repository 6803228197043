// @ngInject
function AcpAnytimeAlertsPaywallComponentCtrl($scope, acpCoreDispatcher) {
  function enrollSuccess(result) {
    $scope.defer.resolve({
      wall: 'acp-anytime-alerts-paywall',
      result: result
    });
  }

  $scope.skipSignup = function() {
    enrollSuccess();
  };

  $scope.$onValue(acpCoreDispatcher.alerts.subscribed.success, enrollSuccess);
}

export default AcpAnytimeAlertsPaywallComponentCtrl;

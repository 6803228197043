import acpPaywallAnytimeAlertsTemplate from './templates/acp-paywall-anytime-alerts.html';
// @ngInject
function acpPaywallAnytimeAlertsDirective() {
  return {
    restrict: 'E',
    scope: {
      defer: '='
    },
    controller: 'AcpAnytimeAlertsPaywallComponentCtrl',
    template: acpPaywallAnytimeAlertsTemplate
  };
}

export default acpPaywallAnytimeAlertsDirective;

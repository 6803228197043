import acpPaywallAnytimeAlertsCtrl from './acp-paywall-anytime-alerts-ctrl';
import acpPaywallAnytimeAlertsDirective from './acp-paywall-anytime-alerts-directive';
import acpComponentAnytimeAlerts from 'components/anytime-alerts-sign-up';
import acpCore from 'core';
import ng from 'angular';

import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywall.aa
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywall-anytime-alerts></acp-paywall-anytime-alerts>
 * ```
 *
 * ```js
 * angular.module('acp.components.paywall-anytime-alerts.example', [
 *   require('acp.component.paywall-anytime-alerts').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.paywall-anytime-alerts', [
    acpCore.name,
    acpComponentAnytimeAlerts.name,
    'netspend.legos.checkbox'
  ])
  .directive('acpPaywallAnytimeAlerts', acpPaywallAnytimeAlertsDirective)
  .controller(
    'AcpAnytimeAlertsPaywallComponentCtrl',
    acpPaywallAnytimeAlertsCtrl
  );

export default component;
